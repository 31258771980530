<template>
  <div>
    <v-card class="pa-2 ma-2">
      <v-layout>
        <v-flex class="mx-2">
          <v-select outlined dense hide-details v-model="filterObj.type" label="Choose Report" :items="listOfReportTypes" item-text="name" item-value="_id"></v-select>
        </v-flex>
        <v-flex class="mx-2">
          <v-menu v-model="filterObj.fromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field hide-details :value="$_formatDate(filterObj.startdate, 'YYYY-MM-DD', 'DD.MM.YYYY')"
              prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="filterObj.startdate" @input="filterObj.fromMenu = false" @change="getAttendanceObj(null)"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex class="mx-2">
          <v-menu v-model="filterObj.toMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field hide-details :value="$_formatDate(filterObj.enddate, 'YYYY-MM-DD', 'DD.MM.YYYY')"
              prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="filterObj.enddate" @input="filterObj.toMenu = false" @change="getAttendanceObj(null)"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex class="mx-2">
          <v-btn color="primary" tile> Run Report </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card class="ma-2 pa-2" height="400">
      Report Rendering Section
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      filterObj: {},
      listOfReportTypes: [
        { _id: 1, name: 'Attendance Report' },
        { _id: 2, name: 'Leave Report' }
      ]
    }
  }
}
</script>

<style>

</style>